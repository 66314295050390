import React from "react";
import config from "../../data/config.json";
import SkillsCard from "../SkillsCard";
import AboutMeTimelineCard from "../AboutMeTimelineCard";

function AboutMe() {
  const { about_me: aboutMe } = config[0];

  return (
    <div className='flex flex-col justify-between w-full px-16 py-6'>
      <div className='mt-12'>
        <div className='flex-grow '>
          {aboutMe.timeline.map((item, index) => {
            return (
              <AboutMeTimelineCard
                date={item.date}
                index={index}
                timeline={aboutMe.timeline}
                title={item.title}
                description={item.description}
              />
            );
          })}
        </div>
      </div>
      <div className='w-full mx-auto mt-6 md::w-4/5 lg:w-4/5'>
        <SkillsCard />
      </div>
    </div>
  );
}

export default AboutMe;
