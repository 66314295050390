import React, { useEffect, useState } from "react";

function SkillBar({ id, name, value }) {
  const [hovering, setHovering] = useState(false);
  const [loadAnimation, setLoadAnimation] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const element = document.getElementById(id);
      if (element) {
        const rect = element.getBoundingClientRect();
        const isInViewport = rect.top >= 0 && rect.bottom <= window.innerHeight;
        if (isInViewport) {
          setLoadAnimation(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [id]);

  return (
    <li
      key={id}
      id={id}
      className='p-1 mb-2 rounded-lg bg-secondary'
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <p className='text-sm font-semibold'>{hovering ? `${value}%` : name}</p>
      <div className='w-full h-4 overflow-hidden bg-gray-200 rounded-full dark:bg-gray-700'>
        <div
          className={`h-4 ${
            hovering ? "bg-green-400" : "bg-green-600"
          } rounded-full transition-all duration-700 ease-in-out ${
            loadAnimation ? "w-0" : ""
          }`}
          style={{ width: loadAnimation ? `${value}%` : "0%" }}
        ></div>
      </div>
    </li>
  );
}

export default SkillBar;
