import React, { useEffect, useRef, useCallback } from "react";

function AboutMeTimelineModal({ title, description, showModal, setShowModal }) {
  const cardRef = useRef();
  const handleClose = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        handleClose();
      }
    };

    // Add event listener
    document.addEventListener("keydown", handleKeyDown);

    // Remove event listener on cleanup
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [handleClose]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cardRef.current && !cardRef.current.contains(event.target)) {
        handleClose();
      }
    };

    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event listener on cleanup
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [handleClose]);

  return showModal ? (
    <div className='fixed inset-0 z-50 flex items-center justify-center scroll-'>
      <div className='fixed inset-0 z-0 w-screen h-screen bg-gray-900 opacity-60'></div>
      <div ref={cardRef} className='z-50 w-5/6 rounded-lg h-5/6'>
        <button
          className='absolute top-0 right-0 m-4 text-3xl font-bold text-gray-500 cursor-pointer'
          onClick={handleClose}
        >
          &times;
        </button>
        <div className='relative w-full h-full p-12 overflow-y-scroll rounded-3xl bg-tertiary'>
          <h1 className='text-3xl font-bold'>{title}</h1>
          <p className='mt-4 text-md'>{description}</p>
          <p className='mt-4 text-md'>{description}</p>
          <p className='mt-4 text-md'>{description}</p>
          <p className='mt-4 text-md'>{description}</p>
          <p className='mt-4 text-md'>{description}</p>
          <p className='mt-4 text-md'>{description}</p>
          <p className='mt-4 text-md'>{description}</p>
          <p className='mt-4 text-md'>{description}</p>
          <p className='mt-4 text-md'>{description}</p>
          <p className='mt-4 text-md'>{description}</p>
        </div>
      </div>
    </div>
  ) : null;
}

export default AboutMeTimelineModal;
