import React, { useEffect, useState } from "react";
import AboutMeTimelineModal from "./AboutMeTimelineModal";

function AboutMeTimelineCard({ date, index, timeline, title, description }) {
  const months = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  };

  const [showModal, setShowModal] = useState(false);
  const [showOverview, setShowOverview] = useState(false);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Escape") {
        setShowModal(false);
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  useEffect(() => {
    if (showModal) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [showModal]);

  let eventDate = new Date(date);
  let dateToDisplay = `${
    months[eventDate.getMonth() + 1]
  } ${eventDate.getFullYear()}`;
  const isOdd = index % 2 !== 0;
  const isLastElement = index === timeline.length - 1;

  return (
    <div
      key={index}
      className={`flex justify-center items-start mb-8 ${
        !isOdd ? "flex-row-reverse" : "flex-row"
      }`}
    >
      <AboutMeTimelineModal
        title={title}
        description={description}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <div
        className={`absolute w-4 rounded-md z-40 transform -translate-x-1/2 ${
          isLastElement ? (showOverview ? "h-80" : "h-64") : "h-80"
        } bg-tertiary left-1/2`}
      ></div>
      <div
        className={`md:w-1/3 lg:w-1/3 w-2/5 ${
          !isOdd ? "items-end" : "items-start"
        } flex`}
      >
        {showOverview ? (
          <div className='relative w-full h-full p-2 my-2 overflow-y-scroll rounded-lg bg-tertiary'>
            <p>{description}</p>
            <button
              className='mt-4 text-xl font-bold text-gray-200 cursor-pointer'
              onClick={() => setShowModal(true)}
            >
              Read more...
            </button>
          </div>
        ) : (
          <div className='w-full p-2 m-4 bg-transparent'></div>
        )}
      </div>
      <div
        className={`flex flex-col cursor-pointer md:w-1/3 lg:w-1/3 w-2/5 p-4 my-2 rounded-lg shadow-lg text-white hover:text-gray-300 bg-tertiary hover:bg-secondary ${
          isOdd ? "text-left" : "text-right"
        }`}
        onClick={() => setShowOverview(!showOverview)}
      >
        <div className='mb-2'>
          <p className='text-sm text-gray-300'>{dateToDisplay}</p>
        </div>
        <div>
          <p className='mb-2 text-xl font-bold'>{title}</p>
        </div>
      </div>
    </div>
  );
}

export default AboutMeTimelineCard;
